@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px); /* Optional: Adjust the initial position */
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  opacity: 0;
  animation: fadeIn 0.8s ease-out forwards; /* Adjust duration and timing function */
  scroll-margin-top: 500px; /* Adjust the margin to your needs */
}